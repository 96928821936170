.hamburger {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    width: 30px;
    height: 23px;
    position: relative;
    &__line,&:before, &:after {
        width: 30px;
        height: 3px;
        content: "";
        display: block;
        background: $color-brand;
        transition: transform .2s ease-in-out, opacity .2s ease-in-out, background .3s ease-in-out; }
    &:before {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        transform-origin: top right; }
    &:after {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      transform-origin: bottom right; }
    &--highlight {
        .hamburger__line,&:before, &:after {
            background: $color-brand; } }
    &--active {
        .hamburger__line {
            background: $color-brand;
            opacity: 0;
            transform: translateX(100%); }
        &:before {
            background: $color-brand;
            transform: rotate(-45deg); }
        &:after {
            background: $color-brand;
            margin-top: -3px;
            transform: rotate(45deg); } } }

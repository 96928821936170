.header {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  &__layout {
    display: flex;
    align-items: center; }
  &__brand {
    flex: none;
 }    // width: 100px
  &__nav {
    margin-left: auto; } }


@include breakpoint(small) {
  .header {
    &__brand {
      width: 60px; } } }

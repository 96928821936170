// --------------------------------------------------------------------------
//            _           _
//   ___  ___| | ___  ___| |_ _ __ ___  _ __
//  / __|/ _ \ |/ _ \/ __| __| '__/ _ \| '_ \
//  \__ \  __/ |  __/ (__| |_| | | (_) | | | |
//  |___/\___|_|\___|\___|\__|_|  \___/|_| |_|
//
// --------------------------------------------------------------------------
//  Version: 2.0.6
//   Author: Simon Sturgess
//     Docs: dahliacreative.github.io/selectron
//     Repo: github.com/dahliacreative/selectron
//   Issues: github.com/dahliacreative/selectron/issues
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// Default Theme Variables
// --------------------------------------------------------------------------

$selectron-border-radius:           4px !default;
$selectron-border-width:            1px !default;
$selectron-color-background-dark:   #363636 !default;
$selectron-color-background-light:  #ffffff !default;
$selectron-color-brand:             #f70c36 !default;

$selectron-color-font-dark:         #666666 !default;
$selectron-color-font-light:        #999999 !default;
$selectron-font-family:             sans-serif !default;
$selectron-font-size:               13px !default;
$selectron-line-height:             1.2 !default;
$selectron-text-align:              left !default;

$selectron-max-width:               400px !default;
$selectron-min-width:               200px !default;
$selectron-height:                  50px !default;
$selectron-options-max-height:      200px !default;

$selectron-padding:                 17px 50px 18px 18px !default;
$selectron-option-padding:          13px 18px 14px !default;
$selectron-optgroup-padding:        13px 18px 14px 28px !default;
$selectron-search-padding:          14px 18px 14px 18px !default;
$selectron-search-height:           45px !default;

$selectron-arrow-border-width:      2px !default;
$selectron-arrow-border-radius:     0px !default;
$selectron-arrow-size:              10px !default;
$selectron-block-width:             50px !default;

$selectron-max-icon-size:           50px !default;
$selectron-icon-margin:             0 10px 0px 0 !default;


// --------------------------------------------------------------------------
// Default Theme
// --------------------------------------------------------------------------

.selectron {
  color: $selectron-color-font-dark;
  display: inline-block;
  font-family: $selectron-font-family;
  font-size: $selectron-font-size;
  max-width: $selectron-max-width;
  min-width: $selectron-min-width;
  position: relative;
  width: 100%;

  &select {
    display: none; }

  &--disabled {
    opacity: 0.5;
    pointer-events: none; }

  &--is-touch & {

    &__select {
      color: $selectron-color-font-dark;
      font-family: $selectron-font-family;
      font-size: $selectron-font-size;
      font-size: $selectron-font-size;
      height: 100%;
      padding: $selectron-padding;
      visibility: visible;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; } }


  // ------------------------------------------------------------------------
  // Select
  // ------------------------------------------------------------------------

  &__select {
    background-color: transparent;
    border: none;
    height: 0;
    left: 0;
    outline: none;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 0; }


  // ------------------------------------------------------------------------
  // Trigger
  // ------------------------------------------------------------------------

  &--is-touch,
  &__trigger {
    background-color: $selectron-color-background-light;
    border-radius: $selectron-border-radius;
    border: $selectron-border-width solid lighten($selectron-color-font-light, 20%);
    box-sizing: border-box;
    color: lighten($selectron-color-font-dark, 30%);
    cursor: pointer;
    font-family: $selectron-font-family;
    font-size: $selectron-font-size;
    height: $selectron-height;
    line-height: $selectron-line-height;
    outline: none;
    overflow: hidden;
    padding: $selectron-padding;
    position: relative;
    text-align: $selectron-text-align;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &::-moz-focus-inner {
      border: none; }

    &--is-filled {
      color: $selectron-color-font-dark; }

    &--is-open {
      border-color: $selectron-color-font-light;
      border-radius: $selectron-border-radius $selectron-border-radius 0 0;
      color: $selectron-color-font-dark; }

    &--is-overflowing {
      border-radius: 0 0 $selectron-border-radius $selectron-border-radius; }

    &:focus {
      border-color: $selectron-color-font-light; }

    &:before {
      content: "";
      border-bottom: $selectron-arrow-border-width solid $selectron-color-brand;
      border-left: $selectron-arrow-border-width solid $selectron-color-brand;
      height: $selectron-arrow-size;
      border-radius: 0 0 0 $selectron-arrow-border-radius;
      width: $selectron-arrow-size;
      position: absolute;
      top: 50%;
      right: calc(20px - #{$selectron-arrow-border-width * 2});
      margin-top: -#{$selectron-arrow-border-width / 2};
      -webkit-transform: rotate(-45deg) translate(50%, -50%);
      -ms-transform: rotate(-45deg) translate(50%, -50%);
      -o-transform: rotate(-45deg) translate(50%, -50%);
      transform: rotate(-45deg) translate(50%, -50%); } }


  // ------------------------------------------------------------------------
  // Search
  // ------------------------------------------------------------------------

  &__search {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: $selectron-border-width solid $selectron-color-font-light;
    border-top: none;
    box-sizing: border-box;
    color: $selectron-color-font-dark;
    font-family: $selectron-font-family;
    font-size: $selectron-font-size;
    height: $selectron-search-height;
    left: 0;
    opacity: 0;
    outline: none;
    padding: $selectron-search-padding;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 100%;

    + .selectron__options {
      top: calc(100% + #{$selectron-search-height}); }

    &--is-overflowing {
      border-bottom: none;
      border-top: $selectron-border-width solid $selectron-color-font-light;
      bottom: 100%;
      top: auto;

      + .selectron__options {
        bottom: calc(100% + #{$selectron-search-height});
        top: auto; } }

    &--is-open {
      opacity: 1;
      visibility: visible; } }


  // ------------------------------------------------------------------------
  // Options
  // ------------------------------------------------------------------------

  &__options {
    background-color: #fff;
    border-radius: 0 0 $selectron-border-radius $selectron-border-radius;
    border: $selectron-border-width solid $selectron-color-font-light;
    border-top: none;
    box-sizing: border-box;
    left: 0;
    list-style: none;
    margin: 0;
    max-height: $selectron-options-max-height;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    text-align: $selectron-text-align;
    top: 100%;
    visibility: hidden;
    width: 100%;
    z-index: 50;
    -webkit-overflow-scrolling: touch;

    &--is-open {
      opacity: 1;
      visibility: visible; }

    &--is-overflowing {
      border-bottom: none;
      border-radius: $selectron-border-radius $selectron-border-radius 0 0;
      border-top: $selectron-border-width solid $selectron-color-font-light;
      bottom: 100%;
      top: auto; } }


  // ------------------------------------------------------------------------
  // Option
  // ------------------------------------------------------------------------

  &__option {
    cursor: pointer;
    line-height: $selectron-line-height;
    overflow: hidden;
    padding: $selectron-option-padding;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--optgroup {
      padding: $selectron-optgroup-padding; }

    &--is-selected {
      background-color: transparent;
      color: $selectron-color-brand; }

    &--is-disabled,
    &[data-value=""] {
      color: lighten($selectron-color-font-dark, 40%); }

    &--is-disabled {
      pointer-events: none; }

    &--is-hidden {
      display: none; }

    &--is-hovered {
      background-color: $selectron-color-brand;
      color: #fff !important; } }


  // ------------------------------------------------------------------------
  // No Results
  // ------------------------------------------------------------------------

  &__no-results {
    line-height: $selectron-line-height;
    padding: $selectron-option-padding; }


  // ------------------------------------------------------------------------
  // Option Group
  // ------------------------------------------------------------------------

  &__option-group {
    color: $selectron-color-font-light;
    cursor: default;
    padding: $selectron-option-padding; }


  // ------------------------------------------------------------------------
  // Icon
  // ------------------------------------------------------------------------

  &__icon {
    display: inline-block;
    margin: $selectron-icon-margin;
    max-width: $selectron-max-icon-size;
    vertical-align: middle; }


  // ------------------------------------------------------------------------
  // Dark Theme
  // ------------------------------------------------------------------------

  &--block {

    .selectron {

      // --------------------------------------------------------------------
      // Trigger
      // --------------------------------------------------------------------

      &__trigger {

        &:before {
          background-color: $selectron-color-brand;
          border: none;
          height: 100%;
          margin: 0;
          right: 0;
          top: 0;
          width: $selectron-block-width;
          -webkit-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none; }

        &:after {
          border-color: #ffffff transparent transparent transparent;
          border-style: solid;
          border-width: ($selectron-arrow-size/2) ($selectron-arrow-size/2) 0 ($selectron-arrow-size/2);
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          top: 50%;
          margin-top: -$selectron-arrow-size/4;
          right: ($selectron-block-width - $selectron-arrow-size)/2; } } } }




  // ------------------------------------------------------------------------
  // Dark Theme
  // ------------------------------------------------------------------------

  &--dark {
    color: #fff;

    .selectron {

      // --------------------------------------------------------------------
      // Trigger
      // --------------------------------------------------------------------

      &__trigger {
        background-color: $selectron-color-background-dark;
        border: none;
        color: $selectron-color-font-dark;

        &--is-filled {
          color: #fff; }

        &--is-open {
          background-color: lighten($selectron-color-background-dark, 5%);
          color: #fff; }

        &:focus {
          background-color: lighten($selectron-color-background-dark, 5%); } }


      // --------------------------------------------------------------------
      // Options
      // --------------------------------------------------------------------

      &__options {
        background-color: $selectron-color-background-dark;
        border: none; }


      // --------------------------------------------------------------------
      // Option
      // --------------------------------------------------------------------

      &__option {

        &[data-value=""] {
          color: $selectron-color-font-dark; }

        &--is-hovered,
        &:hover {
          color: #fff; } } } } }

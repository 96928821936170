.maincontent {
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 80px;
  &--less-bottom {
    padding-bottom: 40px; }
  &--light {
    background: $color-brand-light; }
  &__layout {
    display: flex;
    margin-right: -80px;
    margin-bottom: -80px;
    &--less-space {
      margin-right: -40px;
      margin-bottom: -40px; }
    &--wrap {
      flex-wrap: wrap; }
    &--align-center {
      justify-content: center; } }
  &__col {
    padding-right: 80px;
    padding-bottom: 80px;
    box-sizing: border-box;
    &--50 {
      flex: none;
      width: 50%; }
    &--33 {
      flex: none;
      width: 33.333%; }
    .maincontent__layout--less-space & {
      padding-right: 40px;
      padding-bottom: 40px; } }
  &__title {
    @extend %heading;
    color: $color-brand-dark;
    font-size: 42px;
    margin-bottom: 60px; }
  &__media {
    img {
      width: 100%; } } }


@include breakpoint(large) {
  .maincontent {
    padding-top: 40px;
    padding-bottom: 40px;
    &__layout {
      margin-right: -40px;
      margin-bottom: -40px;
      &--break-at-large {
        display: block;
        margin-right: 0;
        .maincontent__col {
          padding-right: 0;
          width: 100%; } }
      &--wrap-at-large {
        flex-wrap: wrap; } }
    &__col {
      padding-right: 40px;
      padding-bottom: 40px;
      &--50-at-large {
        width: 50%; } }
    &__title {
      margin-bottom: 40px; } } }

@include breakpoint(medium) {
  .maincontent {
    &__title {
      font-size: 32px; } } }

@include breakpoint(small) {
  .maincontent {
    &__layout {
      display: block;
      margin-right: 0; }
    &__col {
      padding-right: 0!important;
      width: 100%!important;
      &--pt-at-small {
        padding-top: 40px; } } } }

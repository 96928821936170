.checklist {
    margin-top: 30px;
    ul {
      @extend %list;
      li {
        position: relative;
        padding-left: 30px;
        padding-bottom: 5px;
        @extend %copy;
        color: $color-text-dark;
        font-size: 16px;
        font-weight: $medium;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 0px;
          width: 14px;
          height: 7px;
          border-bottom: 3px solid $color-text-dark;
          border-left: 3px solid $color-text-dark;
          transform: rotate(-45deg); } } } }


@include breakpoint(small) {
  .checklist {
    margin-top: 20px; } }

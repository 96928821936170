.brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  &__img {
    flex: none;
    width: 60px;
    display: block; }
  &__content {
    margin-left: 10px;
    @extend %copy;
    font-size: 10px;
    color: $color-brand-dark; }
  &__name {
    width: 100%;
    display: block;
    font-weight: $semiBold;
    font-size: 13px;
    // margin-bottom: 2px
    span {
      color: #666; } } }

.center-text {
  text-align: center; }

.wysiwyg {
    h1, h2, h3, h4, h5, h6 {
        @extend %heading;
        margin-bottom: 30px;
        color: $color-brand-dark;
        font-weight: $regular; }
    h1 {
        font-size: 42px; }
    h2 {
        font-size: 32px; }
    h3 {
        font-size: 20px; }
    h4 {
        font-size: 18px; }
    h5 {
        font-size: 16px; }
    h6 {
        font-size: 14px; }
    p {
        @extend %copy;
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 20px;
        line-height: 1.4;
        color: $color-text-dark;
        &:last-child {
            margin-bottom: 0; }
        & + h2, & + h3, & + h4, & + h5, & + h6 {
          margin-top: 30px; } }
    strong {
        font-weight: $bold;
        a {
            text-decoration: none; } }
    a {
      color: $color-brand;
      transition: color .3s ease;
      &:hover {
        color: $color-brand-dark; } }
    ul {
        @include custom-list($color-brand);
        padding: 0 0 20px 20px;
        margin-top: 15px;
        margin-left: 20px;
        &:last-child {
            padding-bottom: 0; } } }


@include breakpoint(medium) {
  .wysiwyg {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 20px; }
    h1 {
        font-size: 32px; }
    h2 {
        font-size: 26px; }
    p {
      & + h2, & + h3, & + h4, & + h5, & + h6 {
          margin-top: 20px; } } } }

.button {
    appearance: none;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    color: $white;
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    text-decoration: none;
    @extend %copy;
    font-weight: $semiBold;
    background: $color-brand;
    box-sizing: border-box;
    transition: background 300ms ease;
    &:hover {
        background: $color-brand-dark; } }

.gallery {
  overflow: hidden;
  &__layout {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-bottom: -5px; }
  &__item {
    flex: none;
    width: 20%;
    padding-right: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    display: block; }
  &__item-inner {
    overflow: hidden;
    img {
      width: 100%;
      display: block;
      transition: opacity .3s ease, transform .3s ease; }
    &:hover {
      img {
        opacity: .8;
        transform: scale(1.2); } } } }


@include breakpoint(large) {
  .gallery {
    &__item {
      width: 25%; } } }

@include breakpoint(medium) {
  .gallery {
    &__item {
      width: 33.333%; } } }

@include breakpoint(small) {
  .gallery {
    &__item {
      width: 50%; } } }

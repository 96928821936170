// --------------------------------------------------------------------------
// list.sass
// --------------------------------------------------------------------------
// This mixin outputs a bulleted list base on the bullet color passed in
// --------------------------------------------------------------------------

@mixin custom-list($bullet-color: $base-color) {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        @extend %copy;
        font-size: $base-font-size;
        &:first-child {
            margin-top: 15px; }
        &:before {
            width: 7px;
            height: 7px;
            position: absolute;
            top: 10px;
            left: 0px;
            background-color: $bullet-color;
            content: "";
            display: block;
            border-radius: 50%; } } }

.team-members {
  overflow: hidden;
  &__layout {
    display: flex;
    flex-wrap: wrap;
    margin-right: -40px;
    margin-bottom: -40px; }
  &__item {
    width: 33.333%;
    padding-right: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    img {
      width: 100%;
      display: block;
      margin-bottom: 30px; }
    p {
      @extend %copy;
      font-size: 16px;
      line-height: 1.4;
      color: $color-text-dark;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; }
      strong {
        color: $color-brand-dark; } } } }


@include breakpoint(large) {
  .team-members {
    &__layout {
      display: block;
      margin-right: 0;
      margin-bottom: 0; }
    &__item {
      width: 100%;
      padding-right: 0;
      display: flex;
      align-items: flex-start;
      padding-bottom: 40px;
      &:last-child {
        padding-bottom: 0; }
      img {
        flex: none;
        width: 30%;
        margin-right: 20px; } } } }

@include breakpoint(small) {
  .team-members {
    &__item {
      display: block;
      img {
        width: 100%;
        margin-right: 0; } } } }

.form {
  &--login {
    max-width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #wpmem_login {
    margin: 0;
    width: 100%;
    fieldset {
      margin: 0; }
    legend {
      text-align: center;
      @extend %heading;
      color: $color-brand-dark;
      font-size: 32px;
      margin-bottom: 40px; } }
  label {
    @extend %copy;
    font-size: 16px;
    font-weight: $bold;
    margin-bottom: 20px;
    line-height: 1.4;
    color: $color-brand-dark;
    margin-bottom: 5px;
    display: block; }
  .div_text {
    margin: 10px 0 20px 0!important;
    input {
      box-sizing: border-box; } }
  .button_div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    input[type=submit] {
      flex: none;
      margin-left: auto!important;
      @extend .button;
      min-width: 180px; }
    input[type=checkbox] + label {
      margin: 0;
      font-weight: $regular;
      font-size: 14px; } }
  .link-text {
    text-align: center!important;
    @extend %copy;
    font-size: 16px;
    font-weight: $regular;
    line-height: 1.4;
    color: $color-brand-dark;
    a {
      color: $color-brand; } } }


@include breakpoint(medium) {
  .form {
    #wpmem_login {
      legend {
        font-size: 26px; } }
    label {
      font-size: 14px; }
    .div_text {
      margin-right: 0;
      width: 100%; } } }

@include breakpoint(small) {
  .form {
    .button_div {
      input[type=submit] {
        width: 100%; } }
    .button_div {
      display: block;
      input[type=checkbox] {
        margin-top: 0; }
      input[type=submit] {
        margin-top: 20px!important; } } } }

.banner {
  position: relative;
  height: 270px;
  background: $black;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent , rgba(#000, .25));
    z-index: -2; }
  &__media {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
 }    // opacity: .85
  &__layout {
    display: flex;
    align-items: flex-end;
    height: 100%; }
  &__title {
    @extend .maincontent__title;
    color: $white!important;
    padding-bottom: 20px;
    margin-bottom: 0!important; } }


@include breakpoint(medium) {
  .banner {
    height: 150px;
    &:after {
      background: linear-gradient(transparent , rgba(#000, .35)); } } }


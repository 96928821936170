.login {
    &__bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top right;
      z-index: -1; }
    &__header {
      background: $white;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px; }
    &__body {
      min-height: 100vh;
      position: relative;
      padding-top: 80px;
      padding-bottom: 80px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      &--thin {
        padding-bottom: 0;
        min-height: calc(100vh - 110px);
        .container {
          position: relative; } } }
    &__form {
      background: $white;
      padding: 40px 20px;
      border-radius: 10px; } }


@include breakpoint(large) {
  .login {
    &__body {
      padding-top: 40px;
      padding-bottom: 40px;
      &--thin {
        padding-bottom: 0; } } } }

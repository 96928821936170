.map {
  width: 100%;
  background: #676767;
  text-align: center;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  &:after {
    content: "";
    @include triangle(70px, $white, down);
    position: absolute;
    top: 0px;
    right: 50%;
    transform: translateX(50%); }
  &__media {
    display: inline-block;
    width: 100%; } }

.login-bar {
  width: 550px;
  max-width: 100%!important;
  padding: 20px!important;
  background: $white;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  #wpmem_login {
    width: 100%;
    legend {
      display: none; }
    fieldset {
      margin: 0!important;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
    // .div_text + label
    //   margin-left: 20px
    label {
      margin-right: 10px;
      margin-bottom: 0!important; }
    .div_text {
      margin: 0!important;
      flex: 1!important; }
    .button_div {
      width: auto!important;
      margin-bottom: 0!important;
      *:not(input[type=submit]) {
        display: none!important; } }
    .link-text {
      display: none;
      flex: none;
      width: 100%;
      text-align: center; }
    label[for="log"] {
      display: none;
      & + .div_text {
        display: none; } } } }


@include breakpoint(medium) {
  .login-bar {
    width: calc(100% - 40px);
    left: 20px;
    #wpmem_login {
      fieldset {
        display: block; }
      .div_text + label {
        margin-left: 0!important; }
      .div_text {
        margin-bottom: 20px;
        width: 100%; }
      .button_div {
        padding-top: 0;
        margin-top: 0px;
        font-size: 0px;
        input[type=submit] {
          font-size: 16px; } } } } }



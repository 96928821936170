.capabilities {
  margin-top: 40px;
  &__header {
    display: flex;
    align-items: center; }
  &__img {
    flex: none;
    width: 86px;
    height: 86px;
    margin-right: 30px;
    border-radius: 50%;
    object-fit: cover; }
  &__title {
    @extend %heading;
    color: $color-brand-dark;
    font-weight: $regular;
    font-size: 20px; } }


@include breakpoint(small) {
  .capabilities {
    margin-top: 0; } }


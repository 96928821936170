.breadcrumb {
  padding-top: 30px;
  padding-bottom: 30px;
  background: $color-brand-light;
  #breadcrumbs {
    @extend %copy;
    color: $color-text-dark;
    font-size: 16px;
    a {
      color: $color-text-dark;
      transition: color .3s ease;
      &:hover {
        color: $color-brand; } } } }


@include breakpoint(medium) {
  .breadcrumb {
    #breadcrumbs {
      font-size: 14px; } } }

.nav {
  &__hamburger {
    display: none; }
  &__list {
    @extend %list;
    display: flex;
    align-items: center; }
  .menu-item {
    padding: 10px;
    border-bottom: 2px solid transparent;
    transition: border-color .3s ease;
    &:last-of-type {
      padding-right: 0; }
    &.current-menu-item {
      border-color: $color-brand; }
    a {
      @extend %copy;
      color: $color-text-dark;
      font-size: 14px;
      text-decoration: none;
      transition: color .3s ease;
      &:hover {
        color: $color-brand; } } } }

@include breakpoint(large) {
  .nav {
    &__hamburger {
      display: block; }
    &__links {
      display: none;
      position: absolute;
      top: 100%;
      left: 0px;
      width: 100%;
      background: $color-brand-dark; }
    &__list {
      display: block;
      text-align: center; }
    .menu-item {
      border-bottom: 1px solid rgba($color-brand-light, .5);
      padding: 15px 0;
      &:last-child {
        border-bottom: none; }
      a {
        color: $white;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block; } } } }

.qualification-group {
  &__title {
    @extend %heading;
    text-transform: uppercase;
    color: $color-text-dark;
    font-weight: $semiBold; }
  &__sub-title {
    @extend %copy;
    font-size: 16px;
    color: $color-text-dark;
    margin-top: 5px; } }

.address {
  margin-top: 20px;
  // margin-bottom: 80px
  &__title {
    @extend %heading;
    text-transform: uppercase;
    color: $color-text-dark;
    font-weight: $semiBold;
    font-size: 20px;
    margin-bottom: 20px; } }


@include breakpoint(small) {
  .address {
    margin-top: 0; } }


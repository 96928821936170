.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box; }

@include breakpoint(xlarge) {
  .container {
    max-width: 1024px; } }

@include breakpoint(large) {
  .container {
    max-width: 768px; } }

@include breakpoint(medium) {
  .container {
    max-width: 640px; } }

@include breakpoint(small) {
  .container {
    max-width: 100%; } }

// +breakpoint(small)
//   .container
//     max-width: 568px

// +breakpoint(xsmall)
//   .container
//     max-width: 320px
